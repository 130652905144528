import React, { useState } from "react";
import ThreeDotLoader from "@ui/ThreeDotLoader";
import OffersV2 from "container/ListingsV2";
import Listings from "container/Listings";
import { ShimmerBox } from "shared/styles/animation";
import { Drawer } from "@ui/antd";
import { Checkbox } from "antd";
import { CloseIcon } from "dumbComponents/CRMV2/CRMDashboard/Components/Icons";
import { BlueButton } from "dumbComponents/ListingsV2/commonStyles";
import moment from "moment";
import { GoogleOutlined } from "@ant-design/icons";
import {
  Wrap,
  HeaderWrap,
  StyledStatus,
  StatusTitle,
  DocWrap,
  DocTitle,
  FooterWrap,
  DateCreatedContent,
  DocContent,
  DateContent,
  DateContentWrap,
  DocTitleWrap,
  Image,
} from "./styles";
import {
  ButtonWrap,
  ProcessingWrap,
  ProcessingTitle,
  CheckboxWrap,
} from "../RecommendedDocs/styles";
import DynamicMenu from "../DynamicMenu";
import SendOffer from "./SendOffer/index";

const Envelopes = ({
  dropdownList,
  mainList,
  docs,
  status,
  id,
  fillAndSign,
  date,
  creatingReceiverView,
  isSelected,
  fetchOfferPackageDocumentsResponse,
  fetchOfferPackageDocuments,
  refreshOfferDocumentsResponse,
  title,
  key,
  isListingsDocumentProcessing,
  fetchListingPackageDocuments,
  fetchListingPackageDocumentsResponse,
  listingIdToView,
  mergedEnvelopes,
  updateMergeEnvelopeObject,
  setViewListingSendUI,
  viewListingSendUI,
  currentEnvelopeId,
  setEnvelopeId,
  currentBrokerageType,
  dealToView,
  offerIdToView,
}) => {
  const { isFetching: fetchingListingPackageDetails } =
    fetchListingPackageDocumentsResponse || {};
  const handleSelectedcheck = (e, eID) => {
    const checked = e.target.checked
    const currDocs = [
      ...mergedEnvelopes
    ]
    currDocs.forEach((item) => {
      if (item.envelope_id === eID && item.status==="completed") {
        item.isSelected = checked
      }
    })
    updateMergeEnvelopeObject(currDocs)
  }
  const { isFetching: fetchingEnv } = refreshOfferDocumentsResponse || {};
  const [currentId, setId] = useState("");

  const [viewOfferSendUI, setViewOfferSendUI] = useState(false)
  const handleSendOffer = (id) => {
    const payload = {
      envelopeIds: [id],
    };

    if(currentBrokerageType == "offers"){
      fetchListingPackageDocuments({
        type:currentBrokerageType,
        offerId: (offerIdToView || dealToView),
        payload,
        callback: () => {
          setViewListingSendUI(true);
        },
      });
    } else {
      fetchListingPackageDocuments({
        type:currentBrokerageType,
        listingId: listingIdToView,
        payload,
        callback: () => {
          setViewListingSendUI(true);
        },
      });
    }
  };

  return (
    <>
      <Wrap key={key}>
          <>
            {id === currentEnvelopeId && (
              <ProcessingWrap
                isDocumentProcessing={isListingsDocumentProcessing}
              >
                <ProcessingTitle>
                  Processing
                  <ThreeDotLoader />
                </ProcessingTitle>
              </ProcessingWrap>
            )}
          </>
          <HeaderWrap>
            <DateContentWrap>
              {status.value === "completed" && (
                <CheckboxWrap
                  style={{
                    marginTop: "15px",
                    marginRight: "4px",
                    marginLeft: "-10px",
                  }}
                >
                  <Checkbox
                    checked={isSelected}
                    onClick={(e) => {
                      handleSelectedcheck(e, id)
                    }}
                  />
                </CheckboxWrap>
              )}
              <DateCreatedContent>Created at</DateCreatedContent>
              <DateContent>{moment(date).format("MM/DD/YY")}</DateContent>
            </DateContentWrap>
            {fetchingEnv ? (
              <ShimmerBox h="20px" w="100px" />
            ) : (
              <StyledStatus status={status.value}>
                <StatusTitle status={status.value}>{status.label}</StatusTitle>
              </StyledStatus>
            )}
          </HeaderWrap>
          <DocContent>
            <DocTitleWrap fw={500}>{title}</DocTitleWrap>
          </DocContent>
          <DocContent>
            {docs &&
              docs.map((doc) => (
                <DocWrap>
                  <DocTitle>{doc.name || "Document 1"}</DocTitle>
                </DocWrap>
              ))}
          </DocContent>
          <FooterWrap justifyEnd={status.value === "buyer_pending"}>
            {status.value === "action_required" &&
              fillAndSign &&
              typeof fillAndSign === "function" && (
                <ButtonWrap
                  style={{
                    width: "20%",
                  }}
                >
                  <BlueButton
                    shortWidth
                    onClick={() => {
                      setEnvelopeId(id);
                      fillAndSign(id);
                    }}
                  >
                    {creatingReceiverView ? (
                      <ThreeDotLoader color="#fff" />
                    ) : (
                      <p>Fill &amp; Sign</p>
                    )}
                  </BlueButton>
                </ButtonWrap>
              )}
            {status.value === "completed" && (
              <ButtonWrap
                style={{
                  width: "20%",
                }}
              >
                <BlueButton shortWidth onClick={() => handleSendOffer(id)}>
                  {fetchingListingPackageDetails ? (
                    <ThreeDotLoader color="#fff" />
                  ) : (
                    <p>Send</p>
                  )}
                </BlueButton>
              </ButtonWrap>
            )}
            <DynamicMenu
              dropdownList={dropdownList}
              mainList={mainList}
              id={id}
            />
          </FooterWrap>
        </Wrap>
    </>
  );
};

export default Listings(OffersV2(Envelopes));
