import React, { useRef, useEffect } from "react"
import ListingsV2 from "container/ListingsV2"
import Listings from "container/Listings"
import TechAssets from "container/TechAssets"
import { Modal } from "@ui/antd"
import ThreeDotLoader from "@ui/ThreeDotLoader"
import createToast from "dumbComponents/ListingsV2/Components/Toast/customToast"
import Loader from "@ui/Loader"
import Confirmation from "dumbComponents/ListingsV2/Components/Confirmation"
import { EyeIcon, ShareIcon,} from "dumbComponents/ListingsV2/icons"
import AddButtonCTA from "dumbComponents/ListingsV2/Components/AddButtonCTA";
import { ExclamationCircleOutlined } from "@ant-design/icons"
import EnvelopesCard from "../../Envelopes/EnvelopeCard"
import Envelopes from "../../Envelopes/index"
import SignedDocumentsListing from "../../Envelopes/SignedDocumentsListing"
import DocumentShimmer from "dumbComponents/ListingsV2/Components/DocumentShimmer"
import { ShimmerBox } from "shared/styles/animation"
import OffersV2 from "container/OffersV2"
import { OFFERS, LISTINGS, PURCHASE } from "dumbComponents/OffersV3/consts"
import {getBrokerageType} from "dumbComponents/Compliance/utils"
import { withRouter } from "react-router-dom"
import {
  Header,
  HeaderWrap,
  LoaderWrap,
  Wrap,
  ProcessingWrap,
  ProcessingTitle,
  ShimmerWrap,
} from "../styles"
import EmptyState from "./EmptyState"


const statusMap = {
  pending: {
    value: "pending",
    label: "Your Signature Pending",
  },
  sent: {
    value: "buyer_pending",
    label: "Seller Signature Pending",
  },
  completed: {
    value: "completed",
    label: "Completed",
  },
  action_required: {
    value: "action_required",
    label: "Your Signature Pending",
  },
  active: {
    value: "active",
    label: "Active",
  },
  voided: {
    value: "void",
    label: "Voided",
  },
  declined: {
    value: "declined",
    label: "Declined",
  },
  sending: {
    value: "sending",
    label: "Sending",
  },
  processing: {
    value: "processing",
    label: "Processing",
  },
}

const statusMapOffers = {
  pending: {
    value: "pending",
    label: "Your Signature Pending",
  },
  sent: {
    value: "buyer_pending",
    label: "Buyer Signature Pending",
  },
  completed: {
    value: "completed",
    label: "Completed",
  },
  action_required: {
    value: "action_required",
    label: "Your Signature Pending",
  },
  active: {
    value: "active",
    label: "Drafted",
  },
  voided: {
    value: "void",
    label: "Voided",
  },
  declined: {
    value: "declined",
    label: "Declined",
  },
  processing: {
    value: "processing",
    label: "Processing",
  },
}

const FillAndSign = ({
  envelopes, 
  dealToView,
  proofOfFundsDocs,
  updateMergeEnvelopeObject,
  mergedEnvelopes,
  fetchMergedEnvelopes,
  fetchMergedEnvelopesResponse,
  createReceiverListingViewV2,
  createReceiverListingViewV2Response,
  toggleListingsDocumentProcessing,
  isListingsDocumentProcessing,
  listingIdToView,
  fetchEnvelopeListingsResponse,
  setEnvelopeId,
  sendForVoidAndDelete,
  toogleVoidAndRestart,
  sendListingPackageMailResponse,
  toogleVoidAndDelete,
  createEditView,
  createEditViewOfferChecklist,
  downloadEnvelopeDoc,
  downloadEnvelopeDocOfferChecklist,
  sendForVoidAndDeleteOfferChecklist,
  createReceiverViewOfferChecklist,
  createReceiverView,
  sendReminderListing,
  sendReminderOfferChecklist,
  toggleAddSignedDocs,
  history: {
    location,
  },
  offerIdToView,
}) => {

  //const { isFetching: fetchingDocs } = getOfferDocumentsResponse || {}
  const currentBrokerageType = getBrokerageType(location)
  useEffect(() => {
    if(currentBrokerageType == OFFERS){
      fetchMergedEnvelopes({
        type:currentBrokerageType,
        offerId: offerIdToView || dealToView
      })
    } else {
      fetchMergedEnvelopes({
        type:currentBrokerageType,
        listingId: listingIdToView
      })
    }
  }, [])

  useEffect(() => {
        if(currentBrokerageType == OFFERS){
      fetchMergedEnvelopes({
        type:currentBrokerageType,
        offerId: offerIdToView || dealToView
      })
    } else {
      fetchMergedEnvelopes({
        type:currentBrokerageType,
        listingId: listingIdToView
      })
    }
  }, [sendListingPackageMailResponse])
  
  const {
    isFetching : fetchingEnvelopes,
    data: envelopeData
  } = fetchEnvelopeListingsResponse || []
  const { isFetching } = fetchMergedEnvelopesResponse || {}
  const textRef = useRef("")
  const {
    isFetching: creatingReceiverView,
  } = createReceiverListingViewV2Response || {}
  const getDropDownMenu = (status) => {
    switch (status) {
      case "active":
        return []
      case "action_required":
        return ACTION_REQUIRED.dropDownMenu
      case "sent":
      case "decline":
        return NON_COMPLETED_OPTION_LIST.dropDownMenu
      case "completed":
        return COMPLETED.dropDownMenu
      default:
        return []
    }
  }

  const handleVoidAndRestart = (id) => {
    toogleVoidAndRestart(id)
  }

  const handleViewEnvelope = (id) => {
    createToast("Opening document...")
    const payload = {
      envelopeId: id,
    }
    if(currentBrokerageType == LISTINGS){
      createReceiverView(payload)
    } else if(currentBrokerageType == OFFERS){
      createReceiverViewOfferChecklist(payload)
    }
  }

  const handleSendReminder = (id) => {
    const payload = {
      envelopeId: id,
    }
    if(currentBrokerageType==LISTINGS){
      sendReminderListing({
        payload
      })
    } else {
      sendReminderOfferChecklist({
        payload,
      })
    }
    createToast("Reminder sent.")
  }

  const handleVoidDocument = (id) => {
    toogleVoidAndDelete(id)
  }

  const handleEditDocument = (id) => {
    createToast("Opening document edit view.")

    if(currentBrokerageType==LISTINGS){
      createEditView({
        payload: {
          envelopeId: id,
        },
      })
    }
    else if(currentBrokerageType==OFFERS) {
      createEditViewOfferChecklist({
        payload: {
          envelopeId: id,
        },
      })
    }
  }

  const handleDownload = (id) => {
    createToast("Downloading...")
    setEnvelopeId(id)
    toggleListingsDocumentProcessing(true)
    const matchingEnvelope = mergedEnvelopes.find(envelope => envelope.envelope_id === id)
    if(currentBrokerageType==LISTINGS){
      downloadEnvelopeDoc({
        payload: {
          envelopeId: id,
        },
        documentDetail: {
          name: `Listing_${matchingEnvelope?.title}`,
        },
        callback: () => {
          setEnvelopeId(null)
          toggleListingsDocumentProcessing(false)
        },
      })
    } else if(currentBrokerageType==OFFERS){
      downloadEnvelopeDocOfferChecklist({
        payload: {
          envelopeId: id,
        },
        documentDetail: {
          name: `Offer_${matchingEnvelope?.title}`,
        },
        callback: () => {
          setEnvelopeId(null)
          toggleListingsDocumentProcessing(false)
        },
      })
    }
  }

  const NON_COMPLETED_OPTION_LIST = {
    viewMenu: [],
    dropDownMenu: [
      {
        label: "VIEW",
        image: <EyeIcon />,
        onClick: id => handleViewEnvelope(id),
        showDivide: true,
      },
      {
        label: "SEND REMINDER",
        image: <ShareIcon />,
        onClick: id => handleSendReminder(id),
        showDivide: false,
      },
      {
        label: "VOID AND DUPLICATE",
        onClick: id => handleVoidAndRestart(id),
      },
      {
        label: "VOID",
        onClick: id => handleVoidDocument(id),
      },
    ],
  }

  const ACTION_REQUIRED = {
    viewMenu: [],
    dropDownMenu: [
      {
        label: "EDIT",
        onClick: id => handleEditDocument(id),
      },
      {
        label: "DOWNLOAD",
        onClick: id => handleDownload(id),
      },
      {
        label: "VOID AND DUPLICATE",
        onClick: id => handleVoidAndRestart(id),
      },
      {
        label: "VOID",
        onClick: id => handleVoidDocument(id),
      },
    ],
  }

  const COMPLETED = {
    viewMenu: [],
    dropDownMenu: [
      {
        label: "VIEW",
        image: <EyeIcon />,
        onClick: id => handleViewEnvelope(id),
        showDivide: true,
      },
      {
        label: "DOWNLOAD",
        onClick: id => handleDownload(id),
        showDivide: true,
      },
      {
        label: "VOID AND DUPLICATE",
        onClick: id => handleVoidAndRestart(id),
        showDivide: false,
      },
    ],
  }

  const getViewMenu = (status) => {
    switch (status) {
      case "active":
        return []
      case "action_required":
        return []
      case "sent":
      case "decline":
        return []
      case "completed":
        return []
      default:
        return []
    }
  }

  const handleFillAndSign = (envelopeId) => {
    toggleListingsDocumentProcessing(true)
    const payload = {
      envelopeId,
    }
    createReceiverListingViewV2({
      type:currentBrokerageType,
      payload,
    })
  }


  return (
    <>
    <Wrap
      isBg
    >
      <HeaderWrap>
        <Header>
          SENT ENVELOPES
        </Header>
        {/* <AddButtonCTA name = "Upload signed documents" onClick={(e) => {
            e.stopPropagation()
            toggleAddSignedDocs(true)
          }}>
        </AddButtonCTA> */}
      </HeaderWrap>
      {(isFetching) ? (
        <ShimmerWrap>
        <ShimmerBox w="100%" h="100px" />
        <ShimmerBox w="100%" h="100px" />
        <ShimmerBox w="100%" h="100px" />
        <ShimmerBox w="100%" h="100px" />
      </ShimmerWrap>
      ) : (
        <>
          {/* <Envelopes /> */}
          {mergedEnvelopes && mergedEnvelopes.length > 0 ? (
            <>
              {mergedEnvelopes && mergedEnvelopes.map(item => (
                <EnvelopesCard
                  dropdownList={getDropDownMenu(item.status)}
                  mainList={getViewMenu(item.status)}
                  docs={item.merged_envelopes}
                  status={currentBrokerageType==OFFERS ? statusMapOffers[item.status] : statusMap[item.status]}
                  id={item.envelope_id}
                  date={item.date}
                  fillAndSign={handleFillAndSign}
                  creatingReceiverView={creatingReceiverView}
                  title={item.title}
                  key={item.envelope_id}
                  isSelected={item.isSelected}
                  currentBrokerageType={currentBrokerageType}
                />
              ))}
            </>
          ) : (
            <EmptyState
              emptyHeader="No envelopes added"
              emptyDescription="Stay on track with Envelopes"
            />
          )}
        </>
      )}
    </Wrap>
    </>
  )
}

export default withRouter(OffersV2(Listings(ListingsV2(TechAssets(FillAndSign)))));
