import React, { useEffect, useState } from "react"
import OffersV2 from "container/OffersV2"
import OldOffers from "container/TechAssets"
import root from "window-or-global"
import queryString from "query-string"
import {
  RecommendedDocs,
  OfferPackage,
} from "./Components"
import {
  SideNav,
  Tabs,
  PubNubForOffersV2,
} from "../components"
import {
  CoverSheetTitle,
  MenuUiWrap,
} from "./styles"
import {
  DEAL_TERMS_STEP,
} from "../consts"

const STEPS = [
  {
    value: "recommended_docs",
    label: "Doc Preparation",
  },
  {
    value: "offer_package",
    label: "Submit Offer",
  },
]

const DocusignDocs = ({
  dealToView,
  getOfferDocuments,
  getOfferDocumentsResponse,
  toggleDocumentTab,
  currentDocumentTab,
  fetchDealTermsInformation,
  location,
  history,
}) => {

  const { search } = root.location
  const parsedQuery = queryString.parse(search)
  const splitUrl = location?.pathname?.split("/") || ""
  const extractedId = splitUrl && splitUrl[splitUrl.length - 1]
  const id = dealToView || extractedId
  const {
    contract_phase,
  } = parsedQuery || {}
  const {
    isFetching: fetchingDocuments,
  } = getOfferDocumentsResponse || {}

  useEffect(() => {
    // getOfferDocuments({
    //   payload: {
    //     offerId: dealToView,
    //   },
    // })
    fetchDealTermsInformation({
      id: dealToView,
      current_offer_step: DEAL_TERMS_STEP,
    })
  }, [])

  const getCurrentDealId = () => {
    const splitUrl = location?.pathname?.split("/") || ""
    const extractedId = splitUrl && splitUrl[splitUrl.length - 1]
    if (isNaN(extractedId)) {
      return ""
    }
    return extractedId
  }

  const getTransactionId = () => {
    if (!parsedQuery || !parsedQuery.transaction_id) return ""
    return parsedQuery.transaction_id
  }
  return (
    <>
    <PubNubForOffersV2
        id={getCurrentDealId()}
        transactionId={getTransactionId()}
      />
      <MenuUiWrap>
          <Tabs 
            className="custom-need-mt"
        steps={STEPS}
        toggleTab={currentTab => toggleDocumentTab(currentTab)}
        currentTab={currentDocumentTab}
        tabWidth="35%"
        currentActiveTab={currentDocumentTab}
        hideExtendedCard
          />
      {currentDocumentTab === "recommended_docs" && (
        <RecommendedDocs />
      )}
      {currentDocumentTab === "offer_package" && (
        <OfferPackage />
          )}
          
    </MenuUiWrap>
    </>
  )
}

export default OffersV2(OldOffers(DocusignDocs))

