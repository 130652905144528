import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import Slider from "react-slick"
import AppContainer from "container/App"
import RoomsContainer from "container/Rooms"
import { HIGHLIGHT_FONT, ATC, MONTSERRAT, INTER } from "@fonts"
import { roomDashedName } from "dumbComponents/VideoRooms/utils"
import FallbackLogo from "images/radius_logo_sq.png"
import root from "window-or-global"
import colors from "@colors"
import Button from "@ui/Button"
import { preTabletWidth } from "@consts"


const SLIDER_SETTINGS = {
  dots: false,
  infinite: true,
  autoplay: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  pauseOnHover: false,
  autoplaySpeed: 5000,
}

const Wrap = styled.div`
  position: relative;
  min-height: 600px;
  max-height: 700px;
  width: 100%;
  background: black;
`

const SliderWrap = styled.div`
  img {
    width: 100%;

    @media(max-width: 768px) {
      height: 520px;
    }
    @media(max-width: 478px) {
      height: 600px;
    }
  }
`

const HeaderWrap = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: baseline;
  position: absolute;
  width: 100%;
  height: 700px;
  

  @media(max-width: 767px) {
    width: 433px;
    margin: 0 auto;
    justify-content: initial;
  }

  @media(min-width: 768px) {
    ${props => props.slideNumber === 2 && `
        align-items: flex-end;
        max-width: 100vw !important;
    `}
  }

  h1 {
    font-family: ${MONTSERRAT};
    font-style: normal;
    font-weight: 700;
    font-size: 54px;
    line-height: 60px;
    letter-spacing: 0.005em;
    color: ${colors.dreamWhite};
    ${props => props.slideNumber === 3 && `
      color: ${colors.framer_color_gray_700};
    `}
    z-index: 100;
    margin-bottom: 18px;

    @media(min-width: 768px) {
      width: 300px;
    }

    @media(min-width: 1280px) {
      font-size: 54px;
      font-weight: 700;
      line-height: 60px;
      letter-spacing: 0.005em;
      text-align: left;
      width: 652px;
    }

    @media(max-width: 767px) {
      font-size: 44px;
      font-weight: 700;
      line-height: 52px;
      letter-spacing: 0.005em;
      text-align: center;
      width: 100%;
      margin-top: 100px;
    }
  }
  p {
    z-index: 100;
    font-family: ${INTER};
    font-style: normal;
    font-weight: 200;
    font-size: 22px;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    color: ${colors.dreamWhite};
    ${props => props.slideNumber === 3 && `
      color: ${colors.framer_color_gray_700};
    `}

    @media(min-width: 768px) {
      width: 300px;
    }

    @media(min-width: 1280px) {
      font-size: 22px;
      font-weight: 300;
      line-height: 30px;
      width: 534px;
      text-align: left;
      ${props => props.slideNumber === 2 && `
        width: 652px;
      `}
    }

    @media(max-width: 767px) {
      font-size: 18px;
      font-weight: 300;
      line-height: 28px;
      letter-spacing: 0em;
      text-align: center;
      width: 100%;

      ${props => props.mobilTextHeight && `
        height: ${props.mobilTextHeight};
      `}
    }
  }

`

const BackgroundImage = styled.div`

  ${props => props.url && `
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url(${props.url});

    @media(max-width: 1011px) {
      background-position: -658px;
    }

    @media(max-width: 767px) {
      background-position: center;
      background-image: url(${props.mobileUrl});
    }
  `}

  ${props => !props.url && `
    background: grey;
  `}
  

  ${preTabletWidth && `
    height: 700px;
    padding-left: 44.5px;
`}
`

const StyledButton = styled(Button)`
    display: none;
    width: 200px;
    @media(max-width: 430px) {
      display: block;
      position: relative;
      top: 318px;
      width: 387px;
      background: #3a60e4;
    }
`

const CardCarousel = ({
  getPromotedRoomsResponse,
  isAppMounted,
}) => {
  const slider = useRef()
  const [i, setIndex] = useState(1)
  // const { data: promotedRoomsData, isFetching } = getPromotedRoomsResponse || {}
  const promotedRoomsData = [
    // {
    //   name: "What would you like to discover today?",
    //   image_url: "https://d3cyzvjm1vex6o.cloudfront.net/images/event_room_lp_carousel_cover.jpg",
    //   description: "Radius Rooms is the most comprehensive schedule of real estate content available … anywhere.",
    // },
    {
      name: "Managing Broker Office Hours",
      image_url: "https://d2fedz0by71ckz.cloudfront.net/images/new-rooms-header-1.png",
      description: "Converse with Radius Managing Brokers Roger Zelaya and Kathy Rzad on all matters related to real estate.",
      mobile_image: "https://d2fedz0by71ckz.cloudfront.net/images/new-rooms-header-1-mobile.png",
      mobile_margin: "40px",
    },
    {
      name: "Rise & Radiate with Kindred Real Estate",
      image_url: "https://d2fedz0by71ckz.cloudfront.net/images/new-rooms-header-2.png",
      description: "Ready to unleash your full potential? Join us for a transformative journey of growth and connection.",
      mobile_image: "https://d2fedz0by71ckz.cloudfront.net/images/new-rooms-header-2-mobile.png",
      mobile_text_height: "85px",
      mobile_margin: "93px",
    },
    {
      name: "Product 101",
      image_url: "https://d2fedz0by71ckz.cloudfront.net/images/new-rooms-header-3.png",
      description: "Whether you are new to Radius or just looking for a refresher, learn how to navigate Radius Office.",
      mobile_image: "https://d2fedz0by71ckz.cloudfront.net/images/new-rooms-header-3-mobile.png",
      mobile_margin: "40px",
    }
  ]
  const [redirectUrl, setUrl] = useState("")
  let roomUrl
  const handleChange = (length) => {
    if (i === length - 1) {
      const roomName = promotedRoomsData && roomDashedName(promotedRoomsData[i].name)
      roomUrl = promotedRoomsData && `/scheduledrooms/${promotedRoomsData[i].id}/${roomName}`
      setUrl(roomUrl)
      setIndex(0)
    } else {
      const roomName = promotedRoomsData && roomDashedName(promotedRoomsData[i].name)
      roomUrl = promotedRoomsData && `/scheduledrooms/${promotedRoomsData[i].id}/${roomName}`
      setUrl(roomUrl)
      setIndex(i + 1)
    }
  }

  const handleRedirection = (index) => {
    // if (index === 0) {
      let element = document.getElementById("scheduled-rooms");
      element && element.scrollIntoView({ behavior: "smooth", block: "start" });
    // } else {
      // root.open(redirectUrl || roomUrl)
    // }
  }
  return (
    <Wrap>
      {/* {isFetching || !isAppMounted ? ( */}
      {!isAppMounted ? (
        <SliderWrap>
          <BackgroundImage url={FallbackLogo} style={{ backgroundSize: "auto" }}/>
        </SliderWrap>
      ) : (
        <Slider {...SLIDER_SETTINGS} ref={slider} beforeChange={() => handleChange(promotedRoomsData.length)}>
          {promotedRoomsData && promotedRoomsData.map((promotedRooms, index) => {
            return (
              <SliderWrap>
                <HeaderWrap
                  className="container"
                  mobilTextHeight={promotedRooms.mobile_text_height}
                  onClick={() => handleRedirection(index)}
                  slideNumber={index + 1}
                >
                  <h1>
                    {/* {index === 0 ? "What would you like to discover today?" : `${promotedRooms.name}`} */}
                    {`${promotedRooms.name}`}
                  </h1>
                  <p>
                    {/* {index === 0 ?
                      "Radius Rooms is the most comprehensive schedule of real estate content available … anywhere."
                        : 
                      `${promotedRooms.description}`
                    } */}
                    {
                      `${promotedRooms.description}`
                    }
                  </p>
                  {/* <StyledButton
                    bsStyle="marineBLueFill"
                    style={{
                      borderRadius: "4px",
                      color: `${colors.white}`,
                      zIndex: "100",
                      marginTop: promotedRooms.mobile_margin,
                      height: "40px",
                    }}
                    onClick={() => handleRedirection(index)}
                  >
                    Start Watching
                  </StyledButton> */}
                </HeaderWrap>
                <BackgroundImage url={promotedRooms.image_url} mobileUrl={promotedRooms.mobile_image}/>
              </SliderWrap>
            )
          })}
        </Slider>
      )}
    </Wrap>
  )
}

export default AppContainer(RoomsContainer(CardCarousel))
